<template>
	<div class="solo-developer">
		<div class="container">
			<Breadcrumb :pages="pages" class="solo-developer__breadcrumb" />
			<Images
				v-if="!isLoad"
				:images="infoDeveloper.images?.map((el) => el)"
				class="solo-developer__images"
			/>
			<or-skeleton v-else class="solo-developer__images--skeleton" />

			<Text
				:is-load="isLoad"
				:text="infoDeveloper.description"
				:title="infoDeveloper.full_name || infoDeveloper.title"
				class="solo-developer__block"
			/>

			<template v-if="!isLoad">
				<Gallery
					class="solo-developer__gallery"
					v-if="infoProject.length"
					:title="$t('general.projects')"
					:content="infoProject"
					:options="{ alt: 'Project logo' }"
					card="ProjectCard"
					size="small"
					@cardClick="redirect"
				/>
			</template>
			<or-skeleton v-else style="width: 100%; height: 390px" />
		</div>
	</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { i18n } from '@/i18n'
import { useStore } from 'vuex'
import { setCommonMetaData } from '@/utils/setMetaData'

import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

import Images from '@/components/SoloPages/Images'
import Breadcrumb from '@/components/Breadcrumb'
import Gallery from '@/components/Gallery.vue'
import Text from '@/components/SoloPages/Text.vue'

export default {
	name: 'SoloDeveloper',
	components: { Text, Breadcrumb, Images, Gallery },
	setup() {
		const infoDeveloper = ref({})
		const infoProject = ref({})
		const isLoad = ref(true)

		const router = useRouter()
		const route = useRoute()
		const store = useStore()

		const pages = computed(() => ({
			first: {
				title: i18n.global.t('general.developers'),
				srcName: 'Developers'
			},
			past: i18n.global.t('general.developers')
		}))

		const redirect = (_, payload) => {
			router.push({
				name: 'SoloProject',
				params: { slug: payload.slug, lang: store.state.uiLanguage }
			})
		}

		const _get = () => {
			createRequest(requestConfigs.GETDevelopersInfo, {
				routerPayload: { slug: route.params.slug }
			})
				.then((result) => {
					infoDeveloper.value = result.response

					setCommonMetaData(
						`${i18n.global.t('titlePages.developer', {
							name: infoDeveloper.value.title
						})} - OWRealty`,
						{
							description: i18n.global.t('meta.developerDesc'),
							type: 'article',
							title: `${i18n.global.t('titlePages.developer', {
								name: infoDeveloper.value.title
							})} - OWRealty`,
							url: window.location.href
						}
					)
				})
				.catch((err) =>
					store.commit('setError', { status: err.errCode || 404 })
				)
				.finally(() => (isLoad.value = false))

			createRequest(requestConfigs.GETProjectList, {
				queryPayload: { developer_slug: route.params.slug }
			}).then((result) => (infoProject.value = result.response.items))
		}

		_get()

		return {
			infoDeveloper,
			infoProject,
			pages,
			isLoad,

			carouselSettings: {
				settings: {
					itemsToShow: 4,
					snapAlign: 'start',
					itemsToScroll: 4,
					wrapAround: false
				},
				breakpoints: {
					320: { itemsToShow: 1 },
					460: { itemsToShow: 2 },
					920: { itemsToShow: 3, touchDrag: true },
					1100: { itemsToShow: 4, itemsToScroll: 0, touchDrag: false }
				}
			},

			redirect
		}
	}
}
</script>

<style lang="scss">
.solo-developer {
	padding-bottom: 50px;

	&__breadcrumb {
		margin-bottom: 30px;
	}

	&__images {
		margin-bottom: 40px;

		&--skeleton {
			height: 380px;
			width: 100%;
			margin-bottom: 40px;

			@media screen and (max-width: 768px) {
				height: 300px;
			}
		}
	}

	&__block {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	&__gallery {
		.project-card__image {
			width: 280px;
			border-radius: 5px;
			overflow: hidden;
			aspect-ratio: 16 / 10;
		}
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 40px;
	}
}
</style>
